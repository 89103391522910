import React from "react";
import { Link } from "react-router-dom";
import "./header.css";

export default function Header() {
  return (
    <header className="header">
      <Link to="/" className="logo">
        ZEN
      </Link>
      <nav className="nav">
        <ul>
          <li>
            <Link to="/" className="nav-link">
              Home
            </Link>
          </li>
          <li>
            <Link to="#product" className="nav-link">
              Product
            </Link>
          </li>
          <li>
            <Link to="#pricing" className="nav-link">
              Pricing
            </Link>
          </li>
          <li>
            <Link to="#our-team" className="nav-link">
              Our Team
            </Link>
          </li>
          <li>
            <Link to="/privacy-policy" className="nav-link">
              Privacy Policy
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}
