import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy: React.FC = () => {
  return (
    <section className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        Zen (hereinafter referred to as "the Company") is a manufacturer and
        distributor of hardwood plywood products. The Company has established
        the following privacy policy (hereinafter referred to as "this Policy")
        regarding the handling of personal information of users in the services
        provided on this website (hereinafter referred to as "the Services").
      </p>

      <h2>Article 1. Personal Information</h2>
      <p>
        "Personal information" refers to "personal information" as defined in
        the Personal Information Protection Law and other applicable laws and
        regulations. It includes information about a living individual that can
        identify the specific individual by name, date of birth, address,
        telephone number, contact information, and other descriptions. This also
        encompasses personal identification information such as appearance,
        fingerprint, voiceprint data, physiological measurements, and health
        insurance policyholder numbers, as well as biometric data.
      </p>

      <h2>Article 2. Methods of Collecting Personal Information</h2>
      <p>
        We may request personal information such as name, date of birth,
        address, telephone number, email address, bank account number, credit
        card number, driver's license number, and biometric data when a user
        registers for use. Biometric data may include fingerprints, facial
        recognition data, voiceprints, and other physiological or behavioral
        characteristics.
      </p>
      <p>
        In addition, transaction records and payment information, including the
        user's personal information, between the user and our partners
        (including information providers, advertisers, advertisement
        distributors, etc.; hereinafter referred to as "Partners") may be
        collected from our Partners.
      </p>

      <h2>Article 3. Purpose of Collecting and Using Personal Information</h2>
      <p>
        The purposes for which we collect and use personal information,
        including biometric data, are as follows:
      </p>
      <ul>
        <li>To provide and manage our Services.</li>
        <li>
          To analyze user biometric data to display personalized statistics and
          enhance user experience.
        </li>
        <li>
          To respond to inquiries from users (including verifying the identity
          of the user).
        </li>
        <li>
          To send information on new features, updates, campaigns, etc., of the
          Services you are using, as well as information on other services
          provided by us.
        </li>
        <li>
          To contact you as necessary for maintenance, important notices, etc.
        </li>
        <li>
          To identify users who have violated the Terms of Use or who are
          attempting to use the Service for fraudulent or unjustified purposes,
          and to refuse their use of the Service.
        </li>
        <li>
          To allow users to view, change, or delete their own registered
          information, or view the status of their use of the site.
        </li>
        <li>To charge users for paid services.</li>
        <li>Purposes incidental to the above purposes of use.</li>
      </ul>

      <h2>Article 4. Consent for Biometric Data Collection</h2>
      <p>
        Explicit Consent: We will obtain your explicit consent before collecting
        or processing your biometric data. This consent will be obtained
        separately from other terms and conditions.
      </p>
      <p>
        Right to Withdraw: You have the right to withdraw your consent for the
        processing of your biometric data at any time by contacting us at the
        information provided in Article 13.
      </p>

      <h2>Article 5. Change of Purpose of Use</h2>
      <p>
        We will change the purpose of use of personal information only when it
        is reasonably deemed that the new purpose is relevant to the purpose
        before the change.
      </p>
      <p>
        In the event of a change in the purpose of use, the Company shall notify
        the user or publicly announce on this website the purpose of use after
        the change by the method prescribed by the Company.
      </p>

      <h2>Article 6. Provision of Personal Information to Third Parties</h2>
      <p>
        Except in the following cases, we will not provide personal information
        to a third party without obtaining the prior consent of the user.
        However, this excludes cases permitted under the Personal Information
        Protection Law and other laws and regulations:
      </p>
      <ul>
        <li>
          When it is necessary for the protection of the life, body, or property
          of an individual and it is difficult to obtain the consent of the
          individual.
        </li>
        <li>
          When it is especially necessary for improving public health or
          promoting the sound growth of children and it is difficult to obtain
          the consent of the person concerned.
        </li>
        <li>
          When it is necessary to cooperate with a national agency, a local
          government, or an individual or entity entrusted by either a national
          agency or local government to execute affairs prescribed by law, and
          obtaining the consent of the individual is likely to impede the
          execution of the affairs in question.
        </li>
      </ul>
      <p>
        When we have notified or announced the following matters in advance:
      </p>
      <ul>
        <li>The purpose of use includes provision to a third party.</li>
        <li>Items of data provided to third parties.</li>
        <li>Means or method of provision to a third party.</li>
        <li>
          Stopping the provision of personal information to third parties at the
          request of the individual.
        </li>
        <li>How to accept the individual's request.</li>
      </ul>
      <p>
        Exceptions: Notwithstanding the preceding paragraph, in the following
        cases, the recipient of such information shall not be considered a third
        party:
      </p>
      <ul>
        <li>
          When we outsource all or part of the handling of personal information
          within the scope necessary to achieve the purpose of use.
        </li>
        <li>
          Cases in which personal information is provided as a result of the
          succession of business due to merger or other reasons.
        </li>
        <li>
          In cases where personal information is used jointly with a specific
          person or entity, and where this fact, the items of personal
          information jointly used, the scope of joint use, the purpose of use
          by the person using the information, and the name of the person
          responsible for managing the personal information are notified to the
          person in advance or are made readily available to the person in
          question.
        </li>
      </ul>

      <h2>Article 7. Disclosure of Personal Information</h2>
      <p>
        When we receive a request for disclosure of personal information from
        the person concerned, we will disclose it to the person without delay.
        However, we may decide not to disclose all or part of the information if
        disclosure would fall under any of the following cases, and if we decide
        not to disclose the information, we will notify you to that effect
        without delay. A fee of 1,000 yen will be charged for each case of
        disclosure of personal information:
      </p>
      <ul>
        <li>
          If there is a risk of harm to the life, body, property, or other
          rights or interests of the person concerned or a third party.
        </li>
        <li>
          If there is a risk of significant hindrance to the proper conduct of
          our business.
        </li>
        <li>In case of violation of other laws and regulations.</li>
      </ul>
      <p>
        Notwithstanding the provisions of the preceding paragraph, in principle,
        the Company will not disclose non-personal information, such as
        historical information and characteristic information.
      </p>

      <h2>Article 8. Correction and Deletion of Personal Information</h2>
      <p>
        If the user's personal information held by the Company is incorrect, the
        user may request the Company to correct, add, or delete (hereinafter
        referred to as "Correction, etc.") the personal information in
        accordance with the procedures prescribed by the Company.
      </p>
      <p>
        If we receive a request from a user as described in the preceding
        paragraph and deem it necessary to respond to the request, we will
        correct the relevant personal information without delay.
      </p>

      <h2>Article 9. Suspension of Use of Personal Information, etc.</h2>
      <p>
        When we receive a request from a customer to stop using or delete
        (hereinafter referred to as "Suspension of Use, etc.") his/her personal
        information on the grounds that it has been handled beyond the scope of
        the purpose of use or that it has been obtained by wrongful means, we
        will conduct the necessary investigation without delay.
      </p>
      <p>
        If, based on the results of the investigation described in the preceding
        paragraph, we determine that it is necessary to comply with the request,
        we will suspend the use of the relevant personal information without
        delay.
      </p>
      <p>
        In the event of Suspension of Use, etc. based on the provisions of the
        preceding paragraph, or in the event of a decision not to suspend use,
        etc., the Company will notify the user of such decision without delay.
      </p>

      <h2>Article 10. Data Security and Retention</h2>
      <p>
        Protection Measures: We implement appropriate technical and
        organizational measures to safeguard personal information, including
        biometric data, against unauthorized access, loss, destruction,
        alteration, or disclosure.
      </p>
      <p>Encryption: Biometric data is encrypted and stored securely.</p>
      <p>
        Retention Period: We retain biometric data only as long as necessary to
        fulfill the purposes outlined or as required by law.
      </p>

      <h2>Article 11. User Rights</h2>
      <p>
        Access and Correction: Users may request access to their personal
        information, including biometric data, and request corrections if
        necessary.
      </p>
      <p>
        Deletion: Users have the right to request the deletion of their personal
        information, subject to applicable laws and regulations.
      </p>
      <p>
        Withdrawal of Consent: Users may withdraw their consent for the
        processing of biometric data at any time by contacting us.
      </p>
      <p>
        How to Exercise Rights: To exercise these rights, please contact us
        using the information provided in Article 13.
      </p>

      <h2>Article 12. Third-Party Processors</h2>
      <p>
        Authorized Service Providers: Biometric data may be processed by
        authorized third-party service providers who comply with our privacy
        standards.
      </p>
      <p>
        Data Protection Agreements: We ensure that any third parties processing
        personal information have data protection agreements in place that
        require them to protect personal information in accordance with this
        Policy and applicable laws.
      </p>

      <h2>Article 13. Changes to Privacy Policy</h2>
      <p>
        The contents of this Privacy Policy may be changed without notice to the
        user, except as otherwise provided in laws and regulations or in this
        Policy.
      </p>
      <p>
        Unless otherwise specified by the Company, the revised Privacy Policy
        shall take effect from the time it is posted on this website.
      </p>
      <p>
        Notification of Significant Changes: For significant changes affecting
        the processing of biometric data or other sensitive personal
        information, we will notify users in advance through appropriate means.
      </p>

      <h2>Article 14. Contact for Inquiries</h2>
      <p>
        For inquiries regarding this Policy or the handling of personal
        information, including biometric data, please contact the following:
      </p>
      <p>Company Name: Zen</p>
      <p>Address: 696 Upper Changi Rd E, #03 305, Singapore 486826</p>
      <p>Email: zen.peaceinapod@gmail.com</p>
      <p>Telephone: +65 9747 2018</p>
      <p>
        Note: This Privacy Policy has been updated to include provisions related
        to the collection and processing of biometric data, in order to analyze
        and show users their statistics. We are committed to protecting your
        personal information and complying with all applicable laws and
        regulations.
      </p>
    </section>
  );
};

export default PrivacyPolicy;
