// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCSMf6M9y0IX9pjrRKEnU96Fv0n7zT1COc",
  authDomain: "zen-pod.firebaseapp.com",
  projectId: "zen-pod",
  storageBucket: "zen-pod.appspot.com",
  messagingSenderId: "27771224423",
  appId: "1:27771224423:web:13a585905d3e538a416fc2",
    measurementId: "G-9295D3R7Q7",
  databaseURL: "https://zen-pod-default-rtdb.asia-southeast1.firebasedatabase.app/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };