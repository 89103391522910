import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'; // Change BrowserRouter to HashRouter
import Header from "./Header/header.tsx";
import HeroSection from "./HeroSection/HeroSection.tsx";
import FeaturesSection from "./FeaturesSection/FeaturesSection.tsx";
import ZenPositionSection from "./ZenPositionSection/ZenPositionSection.tsx";
import CustomizableShellSection from "./CustomizableShellSection/CustomizableShellSection.tsx";
import SoundscapesSection from "./SoundscapesSection/SoundscapesSection.tsx";
import AromatherapySection from "./AromatherapySection/AromatherapySection.tsx";
import PeltierCoolingSection from "./PeltierCoolingSection/PeltierCoolingSection.tsx";
import TeamSection from "./TeamSection/TeamSection.tsx";
import ContactSection from "./ContactSection/ContactSection.tsx";
import Footer from "./Footer/footer.tsx";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy.tsx"; // Import the PrivacyPolicy component

export default function Index() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <div className='Radial-bg' style={{ background: 'radial-gradient(ellipse at center, #D2C0A7, #E9D9CC, #FFF)' }}>
              <HeroSection />
              <FeaturesSection />
            </div>
            <ZenPositionSection />
            <CustomizableShellSection />
            <SoundscapesSection />
            <AromatherapySection />
            <PeltierCoolingSection />
            <div style={{ background: 'radial-gradient(ellipse at top, #D2C0A7, #86827b, #424240)' }}>
              <TeamSection />
              <ContactSection />
            </div>
          </>
        } />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Route for Privacy Policy */}
      </Routes>
      <Footer />
    </Router>
  );
}
