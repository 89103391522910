import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer">
      <p className="footer-text">
        © 2024 Zen Inc. All rights reserved.<br></br>
        <br></br>
        <Link to="/privacy-policy" className="privacy-policy-link">
          {" "}
          Privacy Policy
        </Link>
      </p>
    </footer>
  );
}
